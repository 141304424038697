import { galaxyApp } from '../app'

galaxyApp.factory('CityPass', ['$http', 'Configuration', function($http, Configuration) {
  var cityPassBaseUrl = Configuration.CityPassAPI;

  var cityPassFactory = {
    validate: validatePasses,
  };

  return cityPassFactory;

  function validatePasses (passes = []) {
    var url = cityPassBaseUrl + Configuration.Routes.cityPass.validate;
    return $http.post(url, { city_passes: passes })
      .then(function(response) {
        var passes = response.data.data.attributes;
        return passes
      })
      .catch(function(error) {
        throw error;
      })
  }
}]);
