import angular from 'angular'

var app = angular.module('galaxyCheckout', [
  // 'ngRaven',
  'ngResource',
  'pickadate',
  'ngCookies',
  'ngMessages',
  'vcRecaptcha',
  'ngSanitize',
  'ngCountries',
  'ngStorage',
  'duScroll',
])

app.config(['$locationProvider', function($locationProvider) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false,
    rewriteLinks: false
  });
}]);

app.config(['pickADateProvider', function(pickADateProvider){
  pickADateProvider.setOptions({
    onOpen: function(){
      $(".dropdown-menu").removeClass("show-menu");
    }
  });
}]);

app.config(['$localStorageProvider', function ($localStorageProvider) {
  $localStorageProvider.setKeyPrefix('NyclStorage.');
}]);

app.constant('StubbedData', {
  "generic_tickets": [
    {
      "ticket_type": "adult",
      "name": "Adults",
      "quantity": 0
    },
    {
      "ticket_type": "child",
      "name": "Children",
      "quantity": 0
    }
  ]
});

export { app as galaxyApp }
