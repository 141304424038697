import { galaxyApp } from '../app'

galaxyApp.controller("ConfirmationCtrl", [
  '$scope',
  '$location',
  '$http',
  'Order',
  'EventType',
  'Configuration',
  function($scope, $location, $http, Order, EventType, Configuration) {
    $scope.init = function(){
      $scope.search = $location.search();
      $scope.orderToken = $scope.search.order_token;
      $scope.setOrderInfo();
    }

    $scope.setOrderInfo = function(){
      Order.get( { token: $scope.orderToken },
        function(response){
          $scope.order = response.data.attributes;
          $scope.isCityPassOrder = $scope.order.is_city_pass_order,
          $scope.isCityPassRedemption = $scope.order.is_city_pass,
          $scope.isCityPassC3Redemption = $scope.order.is_city_pass_c3,
          $scope.checkout = $scope.order.checkout;
          $scope.tickets = $scope.order.checkout.tickets;
          $scope.tickets = $scope.checkout.tickets.map((ticket) => {
            if($scope.isCityPassOrder) {
              var adultCityPass = $scope.order.city_passes.find(cp => cp.adult);
              var childCityPass = $scope.order.city_passes.find(cp => cp.child);
              var ticketPass = ticket.child ? childCityPass : adultCityPass;
              ticket.display_title = `${ticket.quantity} ${ticketPass.ticket_redemption_name}`;
            }
            return ticket
          });
          $scope.extras = $scope.order.checkout.extras;
          $scope.eventType = $scope.order.event_type;
          $scope.orderEventName = $scope.order.event_name;
          $scope.eventTime = {
            description: $scope.order.event_name,
            start_date: $scope.order.event_start_date,
            display_start_time: $scope.order.event_start_time,
            show_time: $scope.order.event_show_time,
          };

          $scope.orderNumber = $scope.order.external_order_id;
          $scope.orderTotal = $scope.order.total_paid;
          $scope.totalCoupon = $scope.order.total_coupon;
          $scope.totalTax = $scope.order.total_tax;
          $scope.couponDescription = $scope.order.coupon_description;
          $scope.galaxyOrderNumber = $scope.order.galaxy_order_id;
          $scope.email = $scope.order.email;
          $scope.trackingSent = $scope.order.tracked;
          $scope.orderSupportPhone = $scope.order.support_phone;
          $scope.orderContactEmail = $scope.order.contact_email;
          submitCriteoEvents();
        }
      );
    }

    var submitCriteoEvents = function() {
      if (window.criteo_q) {
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" :
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m"
        : "d";

        var criteoData = [
          { event: "setAccount", account: 60370 },
          { event: "setSiteType", type: deviceType },
          { event: "setEmail", email: $scope.order.email },
          {
            event: "viewSearch",
            checkin_date: $scope.order.start_date,
            nbra: $scope.order.adult_qty,
            nbrc: $scope.order.child_qty,
          },
          {
            event: "trackTransaction",
            id: $scope.orderNumber,
            item: [
              { id: $scope.orderEventName, price: $scope.totalPaid, quantity: 1 }
            ]
          }
        ];

        window.criteo_q.push(
          { event: "setAccount", account: 60370 },
          { event: "setSiteType", type: deviceType },
          { event: "setEmail", email: $scope.email },
          {
            event: "viewSearch",
            checkin_date: $scope.order.event_start_date,
            nbra: $scope.order.adult_qty,
            nbrc: $scope.order.child_qty,
          },
          {
            event: "trackTransaction",
            id: $scope.orderNumber,
            item: [
              { id: $scope.orderEventName, price: $scope.totalPaid, quantity: 1 }
            ]
          }
        );
      }
    }
}]);
