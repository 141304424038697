import { galaxyApp } from '../app'

galaxyApp.controller("CityPassCtrl", [
  '$scope',
  '$location',
  '$http',
  '$window',
  '$sessionStorage',
  'Cart',
  'CityPass',
  'Configuration',
  function(
    $scope,
    $location,
    $http,
    $window,
    $sessionStorage,
    Cart,
    CityPass,
    Configuration
  ) {
    $scope.init = function(){
      $scope.$storage = $sessionStorage; 
      $scope.validCityPasses = $scope.$storage.cityPasses || [];
      $scope.cityPassError = null;
      $scope.maxPasses = 10;
      $scope.values = [];
      for (let i = 0; i < (10 - $scope.validCityPasses.length); i++) {
        $scope.values.push({ 
          value: "",
          passStatus: "",
          error: ""
        })
      }
      $scope.valueResponses = [];
      $scope.defaultCityPassError = "Sorry, we're having trouble validating your CityPass right now. Please try again later.";
      saveCityPassCart()
    }

    $scope.addValue = function() { 
      if ($scope.values.length === 10) {
        return maxPassesReachedError();
      }

      if ( $scope.values.length === 0 || $scope.values[$scope.values.length - 1].value !== "") {
        $scope.values.push({ 
          value: "",
          passStatus: "",
          error: ""
        });
      }
    }

    $scope.removeValue = function(i) { 
      $scope.values[i] = { 
        value: "",
        passStatus: "",
        error: ""
      }
    }

    $scope.validateCityPassList = function () {
      $scope.cityPassError = null;
      $scope.spinnerClass = 'spinner-overlay';  

      $scope.valueResponses = [...$scope.values]
      
        if($scope.validCityPasses.length >= $scope.maxPasses) return maxPassesReachedError();
        let isError = false;
        
        var newPasses = [...$scope.values.filter((code) => code.value !== "").map((code) => { 
          if (code.value !== "") return { barcode: code.value };
        })];  

        CityPass.validate(newPasses)
          .then(function(response) { 
            var allPasses = $scope.validCityPasses.concat(response);
            $scope.validCityPasses = _.uniq(allPasses, (pass) => pass.pass_id);  
            $scope.values = $scope.values.filter((codeValue) => 
              $scope.validCityPasses.find((pass) => pass.ticket_barcode_requested === codeValue.value) === undefined
            );
            saveCityPassCart();
          })
          .catch(function(error) {
            isError = true;
            error.data.errors.response.forEach(err => {
              $scope.valueResponses = $scope.valueResponses.map((codeValue) => { 
                if (err.ticket_barcode_requested === codeValue.value) return {
                  ...codeValue,
                  passStatus: err.success ? "success" : "failure",
                  error: err.success ? "" : err.message ? err.message : $scope.defaultCityPassError
                }  
                return codeValue;
              })
            });
          }).finally(() => {
            if (isError) {
              $scope.values = $scope.valueResponses;
            } else {
              $scope.values = $scope.valueResponses.filter((response) => $scope.validCityPasses.find((pass) => pass.ticket_barcode_requested === response.value) === undefined);
            } 
            $scope.spinnerClass = ''; 
          }) 
    }  

    $scope.removeCityPass = function(cityPass) {
      $scope.validCityPasses = $scope.validCityPasses.filter((pass) => pass.pass_id !== cityPass.pass_id);
      $scope.values.push({ 
        value: "",
        passStatus: "",
        error: ""
      });
      saveCityPassCart();
    }

    $scope.continueToStore = function() {
      saveCityPassCart();
      var hasValidCityPassTypes = validateCityPassTypes();

      if(hasValidCityPassTypes) {
        setCityPassRedemptionType();
        validateAllCityPassBarcodes()
          .then(function(response){
            return createAppCart();
          })
          .catch(function(error) {
            $scope.getApiErrorMessage(error);
          })
          .finally(function() {
            $scope.spinnerClass = '';
          })
      }
    }

    var validateAllCityPassBarcodes = function() {
      if(!$scope.validOrder) return;
      $scope.spinnerClass = 'spinner-overlay';
      var passes = $scope.validCityPasses.map(cp => {
        cp.barcode = cp.ticket_barcode_requested;
        return cp;
      });

      return CityPass.validate(passes)
        .then(function(response) {
          return response;
        })
        .catch(function(error) {
          throw error;
        })
    }

    var createAppCart = function() {
      if(!$scope.validOrder) return;

      var data = {
        city_passes: $scope.validCityPasses,
        is_city_pass_order: $scope.$storage.isCityPassOrder,
        is_city_pass: $scope.$storage.isCityPassRedemption,
        is_city_pass_c3: $scope.$storage.isCityPassC3Redemption,
      }

      return Cart.create(data)
        .then(function(response) {
          redirectToStore(response)
        })
        .catch(function(error) {
          throw error
        })
    }

    var redirectToStore = function(cart) {
      $scope.$evalAsync(function(){
        var orderParams = {
          cityPass: cart.is_city_pass_order,
          cartToken: cart.token,
        }
        var orderQueryString = $scope.objectToStringQuery(orderParams);

        $window.location.href = `${Configuration.AppCompany}?${orderQueryString}`;
      });
    }

    var setCityPassRedemptionType = function() {
      $scope.$storage.isCityPassOrder = !!$scope.hasCityPass || !!$scope.hasC3;
      $scope.$storage.isCityPassRedemption = !!$scope.hasCityPass;
      $scope.$storage.isCityPassC3Redemption = !!$scope.hasC3;
    }

    var setCityPassTypes = function() {
      $scope.hasC3 = !!$scope.validCityPasses.find(cp => cp.is_c3);
      $scope.hasCityPass = !!$scope.validCityPasses.find(cp => cp.is_city_pass);
      setCityPassRedemptionType();
    }

    var hasValidCityPasses = function() {
      return $scope.validCityPasses.length > 0;
    }

    var validateCityPassTypes = function() {
      var hasMixedCityPassTypes = $scope.hasC3 && $scope.hasCityPass;
      var missingCityPasses = !hasValidCityPasses()

      if(hasMixedCityPassTypes) {
        $scope.validOrder = false;
        swal({
          title: 'CityPass Redemption Error',
          text: 'Your order contains both CityPass and C3 passes. Redemption can only be completed for one type of pass at a time (all C3 or all CityPass). Please edit your applied passes and try again.',
          icon: 'error'
        });
        return false;
      }

      if(missingCityPasses) {
        $scope.validOrder = false;
        swal({
          title: 'CityPass Redemption Error',
          text: 'No CityPASS barcodes added. Please enter and apply your barcodes before clicking CONTINUE.',
          icon: 'error'
        });
        return false
      }

      $scope.validOrder = true;
      return true;
    }

    var saveCityPassCart = function() {
      $scope.$storage.cityPasses = $scope.validCityPasses;
      setCityPassTypes();
    }

    var maxPassesReachedError = function() {
      $scope.cityPassError = "Maximum of 10 CityPasses per order. To redeem more than 10 passes please visit the Circle Line box office.";
    }
}]);
