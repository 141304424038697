import { galaxyApp } from '../app'

galaxyApp.controller('EventTypesCtrl', [
  '$scope',
  '$http',
  'Configuration',
  'EventType',
  'EventTime',
  'Ticket',
  'Capacity',
  'StubbedData',
  'Cart',
  '$filter',
  '$window',
  '$location',
  '$cookies',
  '$document',
  function(
    $scope,
    $http,
    Configuration,
    EventType,
    EventTime,
    Ticket,
    Capacity,
    StubbedData,
    Cart,
    $filter,
    $window,
    $location,
    $cookies,
    $document,
  ) {
    $scope.init = function(){
      $scope.spinnerClass = '';
      $scope.resale = Configuration.Resale;
      $scope.search = $location.search();
      $scope.eventTypeId = parseInt($scope.search.eventTypeId || 0, 10);
      $scope.eventTimeId = $scope.search.eventTimeId && parseInt($scope.search.eventTimeId, 10);
      $scope.partnerId = parseInt($scope.search.partnerId, 10) || null;
      $scope.adultQty = $scope.search.adultQty || $cookies.get('adultQty') || 1;
      $scope.childQty = $scope.search.childQty || $cookies.get('childQty') || 0;
      $scope.cartToken = $scope.search.cartToken;
      $scope.isCityPassOrder = false;
      $scope.isCityPassRedemption = false;
      $scope.isCityPassC3Redemption = false;
      $scope.validCityPasses = [];
      setFilteringInfo();
      setInitialOrderValues();
      $scope.unavailableTickets = [];
    };

    var setInitialOrderValues = function() {
      if($scope.cartToken) {
        Cart.get($scope.cartToken)
          .then(function(response) {
            $scope.isCityPassOrder = response.is_city_pass_order;
            $scope.isCityPassRedemption = response.is_city_pass;
            $scope.isCityPassC3Redemption = response.is_city_pass_c3;
            $scope.validCityPasses = response.city_passes;
            $scope.adultQty = response.adult_quantity || 0;
            $scope.childQty = response.child_quantity || 0;
            setInitialValues();
            setFilteringInfo();
            setTicketQuantities();

          })
          .catch(function(error) {
            console.log('EventTypeCtrl getCart error', error)
          })
      } else {
        setInitialValues();
        setFilteringInfo();
        setTicketQuantities();
      }
    }

    var setFilteringInfo = function() {
      $scope.filteringInfo = {
        eventTypeId: $scope.eventTypeId,
        eventTimeId: $scope.eventTimeId,
        adultQty: $scope.adultQty,
        childQty: $scope.childQty,
        startDate: $scope.search.startDate
      }
    };

    var setInitialValues = function(){
      $scope.totalTickets = 0;
      $scope.setEventTypes();
      $scope.eventType = null;
      $scope.setTicketFilters();
      $scope.setStartDate();
      $scope.time = null;
      $scope.errors = {
        any: true,
        totalTickets: null
      }
    };

    $scope.setEventType = function(eventType){
      clearEventTimeId()
      $scope.eventType = null;
      $scope.eventTypeId = null;
      markEventTypeSelected(eventType)
      scrollToEvents()
      $scope.errors.any = false;

      if (!$scope.errors.any){
        $scope.submitFilters();
      }
    }

    var scrollToEvents = function(){
      if(!$scope.isCityPassOrder) return
      var eventsElement = angular.element(document.getElementById('events-anchor'))
      $document.scrollToElement(eventsElement, 100, 500)
    }

    var markEventTypeSelected = function(eventType) {
      $scope.eventTypes.forEach(e => {
        if(e.id === eventType.id && !e.selected) {
          $scope.eventType = eventType;
          e.selected = true;
        } else {
          e.selected = false;
        }
      });
    }

    $scope.setEventTypes = function(){
      EventType.get({}, function(response){
        $scope.eventTypes = _.filter(response.data.attributes, function(eventType) {
          if (Configuration.Resale) {
            return eventType.resale_display;
          } else {
            return eventType.display;
          }
        });

        if($scope.isCityPassC3Redemption) {
          $scope.eventTypes = _.filter($scope.eventTypes, function(eventType) {
            return eventType.city_pass_c3_eligible;
          })
        } else if($scope.isCityPassOrder) {
          $scope.eventTypes = _.filter($scope.eventTypes, function(eventType) {
            return eventType.city_pass_eligible;
          })
        } 

        $scope.eventTypeIds = _.pluck($scope.eventTypes, 'event_type_id');
        $scope.eventType = _.find($scope.eventTypes, function(eventType){
          // if ($scope.partnerId) {
          //   return (eventType.event_type_id === $scope.eventTypeId) && (eventType.partner_id === $scope.partnerId);
          // }
          return eventType.event_type_id === $scope.eventTypeId && !eventType.combo;
        });
        if ($scope.startDate && $scope.totalTickets > 0){
          $scope.submitFilters();
        }
      }, function(error){
        console.log('setEventTypes error', error)
      });
    };

    $scope.cityPassUpgradePrice = function(eventType) {
      // if no price returned, cruise is not upgrade eligible
      if($scope.isCityPassOrder && $scope.isCityPassRedemption && eventType.city_pass_upgrade) {
        return eventType.city_pass_upgrade_price
      }

      if($scope.isCityPassOrder && $scope.isCityPassC3Redemption && eventType.city_pass_c3_upgrade) {
        return eventType.city_pass_c3_upgrade_price
      }

      return null
    }

    $scope.setStartDate = function(){
      $scope.todayDate = new Date();
      $scope.startDate = $scope.filteringInfo.startDate ? $scope.parseDate($scope.filteringInfo.startDate) : $scope.todayDate;
      if ($scope.startDate < $scope.todayDate){
        $scope.startDate = $scope.todayDate;
      }
    };

    $scope.parseDate = function(date){
      var array = date.split("-");
      var newDate = new Date(array[0], array[1]-1, array[2]);
      return newDate;
    };

    $scope.toggleCruiseDescription = function(eventTime) {
      eventTime.showCruiseDescription = !eventTime.showCruiseDescription
    }

    $scope.setTicketFilters = function(){
      $scope.ticketFilters = StubbedData.generic_tickets;
      $scope.adultTicket = _.find($scope.ticketFilters, function(ticketFilter){ return ticketFilter.ticket_type === "adult" });
      $scope.childTicket = _.find($scope.ticketFilters, function(ticketFilter){ return ticketFilter.ticket_type === "child" });
      setTicketQuantities();
    };

    var setTicketQuantities = function(){
      $scope.adultTicket.quantity = parseInt($scope.filteringInfo.adultQty, 10);
      $scope.childTicket.quantity = parseInt($scope.filteringInfo.childQty, 10);
      $scope.totalTickets = $scope.adultTicket.quantity + $scope.childTicket.quantity;
    };

    $scope.checkSelectedTickets = function(eventTime) {
      $scope.totalTickets > 0 ? $scope.validateTicketTypes(eventTime) : swal('No Tickets Selected', 'Please add tickets to your order before proceeding.');
    }

    $scope.validateTicketTypes = function(eventTime) {
      $scope.spinnerClass = 'spinner-overlay'
      $scope.eventTime = eventTime;
      $scope.partnerId = $scope.eventTime.partner_id
      $scope.resourceId = $scope.eventTime.resource_id

      Ticket.get({ event_type_id: eventTime.event_type_id, partner_id: $scope.partnerId, resource_id:  $scope.resourceId }, function(response){
        var availableTicketTypes = response.data.attributes.map(function(ticket) { return ticket.ticket_type });
        var selectedTypes = $scope.selectedTicketTypes();

        $scope.unavailableTickets = _.reject(selectedTypes, function(ticketType) {
          return availableTicketTypes.indexOf(ticketType.ticket_type) !== -1 ;
        });

        $scope.runTicketTypeErrorCheck();
      }, function(error){
        console.log('Ticket type error', error)
      });
    };

    $scope.runTicketTypeErrorCheck = function() {
      $scope.unavailableTickets.length > 0 ? $scope.ticketTypesInvalid() : $scope.ticketTypesValid();
    };

    $scope.ticketTypesInvalid = function() {
      var unavailableTypes = $scope.unavailableTickets.map(function(type) { return type.ticket_type }).join(', ');
      var errorMessage = `Sorry, ${unavailableTypes} priced tickets do not apply for the selected cruise`;
      $scope.spinnerClass = ''

      swal({
        title: errorMessage,
        text: "Please edit your ticket type selections"
      });
    };

    $scope.ticketTypesValid = function() {
      var ticketTotalsValid = $scope.validateTicketLimit($scope.totalTickets);
      if (ticketTotalsValid) {
        $scope.holdTickets();
      } else {
        $scope.spinnerClass = '';
        $scope.ticketLimitExceeded()
      }
    };

    $scope.holdTickets = function() {
      $http.post(Configuration.API + Configuration.Routes.orders.hold, { event_id: $scope.eventTime.id, section_id: $scope.eventTime.section_id, quantity: $scope.totalTickets })
        .then(function(response) {
          if (response.status === 200) {
            var orderQueryObject = {
              eventTypeId: $scope.eventTime.event_type_id,
              eventTimeId: $scope.eventTime.id,
              adultQty: $scope.adultTicket.quantity,
              childQty: $scope.childTicket.quantity,
              startDate: $scope.startDateString,
              sectionId: $scope.eventTime.section_id,
              resourceId: $scope.eventTime.resource_id,
              partnerId: $scope.eventTime.partner_id,
              holdCapacityId: response.data.hold_capacity_id,
              holdSessionId: response.data.hold_session_id,
            }

            if ($scope.isCityPassOrder) {
              orderQueryObject.cityPass = true;
              orderQueryObject.cartToken = $scope.cartToken;
            }

            var queryValue = $scope.objectToStringQuery(orderQueryObject);
            var url = `${Configuration.AppCompany}/orders/new?${queryValue}`;

            $window.location.href = url;
          } else {
            $scope.spinnerClass = '';
            swal('Unable to hold requested ticket quantities.')
          }
        }, function(errorResponse) {
          $scope.spinnerClass = '';
          var errorMessage = (errorResponse && errorResponse.data.errors.message) || 'Unable to hold requested ticket quantities.'
          swal(errorMessage);
        });
    }

    $scope.selectedTicketTypes = function() {
      var selectedTypes = [];

      if ($scope.adultTicket.quantity > 0) {
        selectedTypes.push($scope.adultTicket);
      }

      if ($scope.childTicket.quantity > 0) {
        selectedTypes.push($scope.childTicket);
      }

      return selectedTypes
    };

    $scope.subtractTicket = function(ticket){
      if (ticket.quantity > 0){
        ticket.quantity -= 1;
      }
      $scope.refreshTotalTickets();
    };

    $scope.addTicket = function(ticket){
      if (ticket.quantity < Configuration.MaxTickets) {
        ticket.quantity += 1;
      }
      $scope.refreshTotalTickets();
    };

    $scope.displayTotalTickets = function(){
      if ($scope.totalTickets === 0){
        return '# of Tickets';
      } else if ($scope.totalTickets === 1) {
        return `${$scope.totalTickets} Ticket`;
      } else {
        return `${$scope.totalTickets} Tickets`;
      }
    };

    $scope.refreshTotalTickets = function(){
      var total = 0;
      angular.forEach($scope.ticketFilters, function(ticket, index){
        total += ticket.quantity;
      });
      $scope.totalTickets = total;
      $scope.refreshTicketsAvailable();
    };

    $scope.refreshTicketsAvailable = function() {
      angular.forEach($scope.eventTimes, function(eventTime){
        eventTime.remaining = eventTime.available - $scope.totalTickets;
        eventTime.sold_out = eventTime.remaining < 0;

        var now = new Date();
        var startTime = eventTime.start_date.getTime();
        var startDateWithTime = new Date(startTime);
        var endTime = eventTime.end_date.getTime();
        var endDateWithTime = new Date(endTime);

        startDateWithTime.addHours(eventTime.start_time.hour);
        startDateWithTime.addMinutes(eventTime.start_time.minute);

        endDateWithTime.addHours(eventTime.end_time.hour);
        endDateWithTime.addMinutes(eventTime.end_time.minute);

        var startInFuture = startDateWithTime > now;
        var endInPast = endDateWithTime < now;

        if (!startInFuture && !eventTime.is_all_day) {
          eventTime.sold_out = true;
        } else if (eventTime.is_all_day && endInPast ) {
          eventTime.sold_out = true;
        }
        eventTime.displayVacancy = eventTime.vacancy < 0 ? 0 : eventTime.vacancy;
      });
    };

    $scope.submitTicketFilters = function() {
      clearEventTimeId()
      $scope.submitFilters()
    }

    $scope.submitFilters = function() {
      $scope.transformSelections();
      $scope.runErrorChecks();
      if (!$scope.errors.any){
        $scope.spinnerClass = 'spinner-overlay';

        var eventTimeParams = {
          event_type_id: $scope.eventTypeId,
          start_date: $scope.startDateString,
          end_date: $scope.startDateString,
          partner_id: $scope.partnerId,
          is_city_pass_core: $scope.isCityPassOrder && $scope.isCityPassRedemption,
          is_city_pass_c3: $scope.isCityPassOrder && $scope.isCityPassC3Redemption,
        };

        if($scope.isCityPassOrder) {
          // force api call to be identified as a city pass order by including city_pass in url params
          eventTimeParams.city_pass = true
        }

        $scope.setCookies();
        EventTime.query(eventTimeParams, function(response){
          $scope.eventTimes = _.filter(response.data.data.attributes, function(eventTime) {
            return _.contains($scope.eventTypeIds, eventTime.event_type_id)
          });

          $scope.partnerId = parseInt($scope.search.partnerId, 10) || null;

          if ($scope.partnerId) {
            $scope.eventTimes = _.filter($scope.eventTimes, function(eventTime) {
              return eventTime.partner_id === $scope.partnerId;
            });
          }
          $scope.filterByEventTimeId()
          $scope.sortByEventTimeCombos()
          $scope.addTimeValueToEventTimes();
          $scope.sortEventTimesByTime()
          $scope.refreshTicketsAvailable();
          $scope.spinnerClass = '';
          $scope.submittingDate = false;
        }, function(error){
          $scope.spinnerClass = '';
          $scope.submittingDate = false;
        });
      } else {
        $scope.submittingDate = false;
      }
    };

    var clearEventTimeId = function() {
      $scope.eventTimeId = null;
      $cookies.remove('eventTimeId')
      return true
    }

    $scope.setActiveEventTimes = function() {
      _.filter(scope.eventTimes, function(eventTime) {
        return _.contains(scope.eventTypeIds, eventTime.event_type_id)
      });
    }

    $scope.transformSelections = function(){
      $scope.startDateString = $scope.dateToString($scope.startDate);
      if ($scope.eventType){
        $scope.eventTypeId = $scope.eventType.event_type_id;
        $scope.partnerId = $scope.eventType.partner_id || $scope.partnerId;
      }
    };

    $scope.runErrorChecks = function(){
      if ($scope.totalTickets < 1){
        $scope.errors.totalTickets = "How many tickets do you need?";
        $scope.errors.any = true;
      } else {
        $scope.errors.any = false;
      }
    };

    $scope.addTimeValueToEventTimes = function() {
      $scope.eventTimes = $scope.eventTimes.map(function(eventTime) {
        eventTime.time_value = $scope.eventTimeDisplayTimeValue(eventTime);
        return eventTime;
      });
    }

    $scope.sortEventTimesByTime = function() {
      $scope.eventTimes = _.sortBy($scope.eventTimes, function(eventTime, index){
        return $scope.eventTimeDisplayTimeValue(eventTime);
      });
    };

    $scope.sortByEventTimeId = function() {
      $scope.eventTimes = _.sortBy($scope.eventTimes, function(eventTime, index){
        return eventTime.id;
      });
    };

    $scope.filterCombos = function(collection, isCombo) {
      return _.filter(collection, function(eventTime) {
        return eventTime.combo === isCombo
      });
    }

    $scope.filterByEventTimeId = function() {
      if ($scope.eventTimeId) {
        $scope.eventTimes = _.filter($scope.eventTimes, function(eventTime) {
          return eventTime.id === $scope.eventTimeId
        });
      }
    }

    $scope.sortByEventTimeCombos = function() {
      $scope.eventTimes = _.sortBy($scope.eventTimes, function(eventTime, index){
        return eventTime.combo;
      });
    };

    $scope.eventTimeDisplayTimeValue = function(eventTime) {
      if (eventTime.display_start_time === "All Day") {
        return 0;
      }
      return eventTime.start_time.hour + (eventTime.start_time.minute / 60);
    }

    $scope.setCookies = function() {
      $cookies.put('adultQty', $scope.adultTicket.quantity);
      $cookies.put('childQty', $scope.childTicket.quantity);
      $cookies.put('eventTypeId', $scope.eventTypeId);
      if ($scope.eventTime){
        $cookies.put('eventTimeId', $scope.eventTime.id);
        $cookies.put('sectionId', $scope.eventTime.section_id);
      }
    };

    $scope.goToResults = function(basePath){
      $scope.transformSelections();
      var queryParams = [
        { key: 'startDate', value: $scope.startDateString },
        { key: 'eventTypeId', value: $scope.eventTypeId },
        { key: 'adultQty', value: $scope.adultTicket.quantity },
        { key: 'childQty', value: $scope.childTicket.quantity },
      ]
      var params = _.map(queryParams, function(param, index){
        var operator = index === 0 ? '?' : '&'
        return operator + param.key + "=" + param.value;
      });
      paramsString = params.join('');
      window.location.href = basePath + paramsString;
    };

    $scope.next = function(){
      $scope.submittingDate = true;
      clearEventTimeId()
      // change in startDate should trigger watch to submitFilters
      $scope.startDate = new Date($scope.startDate.getTime() + (24 * 60 * 60 * 1000));
    };

    $scope.prev = function(){
      $scope.submittingDate = true;
      clearEventTimeId()
      // change in startDate should trigger watch to submitFilters
      $scope.startDate = new Date($scope.startDate.getTime() - (24 * 60 * 60 * 1000));
    };

    $scope.$watch('startDate', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        clearEventTimeId()
        $scope.submitFilters();
      }
    }, true);

    $scope.isCurrentDate = function(){
      return $scope.startDate <= new Date();
    };
  }
]);

