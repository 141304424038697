$(document).ready(function() {
  $(".calendar-button").on("click", function(e) {
    var picker = $(".triggerPickADate").pickadate('picker');
    // var picker = $(".triggerPickADate").pickadate();
    e.stopPropagation();

    if (picker.get('open')) {
      picker.close();
    } else {
      picker.open();
    }
  });
});
