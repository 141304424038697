import { galaxyApp } from '../app'

galaxyApp.factory('Cart', ['$http', 'Configuration', function($http, Configuration) {
  var cartApiBaseUrl = Configuration.AppApi + Configuration.Routes.carts.resource;

  var cartFactory = {
    create: createCart,
    get: getCart,
    getCartUrl: getCartUrl,
  };

  return cartFactory;

  function getCart (cartToken) {
    var url = getCartUrl(cartToken);
    return $http.get(url)
      .then(function(response) {
        var cart = response.data.data.attributes;
        return cart;
      })
      .catch(function(error) {
        throw error;
      })
  }

  function getCartUrl (cartToken) {
    return `${cartApiBaseUrl}/${cartToken}`;
  }

  function createCart (cartParams) {
    return $http.post(cartApiBaseUrl, { cart: cartParams })
      .then(function(response) {
        var cart = response.data.data.attributes;
        return cart;
      })
      .catch(function(error) {
        throw error;
      })
    }
}]);
