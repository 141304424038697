import { galaxyApp } from '../app'

galaxyApp.directive('googleplace', function() {
  return {
    scope: { },
    link: function(scope, element, attrs) {
      var options = {
        types: [],
        componentRestrictions: {}
      };

      scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

      google.maps.event.addListener(scope.gPlace, 'place_changed', function() {
        var geoComponents = scope.gPlace.getPlace();
        var addressComponents = geoComponents.address_components;

        var filteredAddressComponents = addressComponents.reduce((acc, component) =>  ({
          ...acc,
          [component.types[0]]: component.long_name
        }), {});

        scope.$apply(function() {
          // Apply values to customerDetails
          scope.$parent.customerDetails.address_line1 = `${filteredAddressComponents?.street_number || ""} ${filteredAddressComponents?.route || ""}`;
          scope.$parent.customerDetails.zip = filteredAddressComponents?.postal_code || "";
          scope.$parent.customerDetails.city = filteredAddressComponents?.locality || "";
          scope.$parent.customerDetails.state = filteredAddressComponents?.administrative_area_level_1 || "";
          scope.$parent.customerDetails.country = filteredAddressComponents?.country || "";
        });
      });
    }
  };
});

galaxyApp.controller("OrderCtrl", [
  '$scope',
  '$document',
  '$sce',
  'EventType',
  'EventTime',
  'Ticket',
  'Capacity',
  '$filter',
  '$location',
  'Order',
  '$http',
  '$cookies',
  '$window',
  '$timeout',
  '$sessionStorage',
  'Checkout',
  'Extra',
  'CouponMappingCheck',
  'vcRecaptchaService',
  'Configuration',
  'Cart',
  function(
    $scope,
    $document,
    $sce,
    EventType,
    EventTime,
    Ticket,
    Capacity,
    $filter,
    $location,
    Order,
    $http,
    $cookies,
    $window,
    $timeout,
    $sessionStorage,
    Checkout,
    Extra,
    CouponMappingCheck,
    vcRecaptchaService,
    Configuration,
    Cart,
  ) {
    $scope.init = function() {
      $scope.$storage = $sessionStorage;
      $scope.search = $location.search();
      $scope.cartToken = $scope.search.cartToken;
      $scope.eventTypeId = parseInt($scope.search.eventTypeId, 10) || $cookies.get('eventTypeId')
      $scope.eventTimeId = parseInt($scope.search.eventTimeId, 10) || $cookies.get('eventTimeId')
      $scope.partnerId = parseInt($scope.search.partnerId, 10) || $cookies.get('partnerId')
      $scope.sectionId = parseInt($scope.search.sectionId, 10) || $cookies.get('sectionId')
      $scope.resourceId = parseInt($scope.search.resourceId, 10) || $cookies.get('resourceId')
      $scope.startDate = $scope.search.startDate || $cookies.get('startDate');
      $scope.endDate = $scope.search.endDate || $cookies.get('endDate');
      $scope.coupon = $scope.search.coupon || null;
      $scope.holdCapacityId = $scope.search.holdCapacityId;
      $scope.holdSessionId = $scope.search.holdSessionId;
      $scope.skipBillingDetails = false;
      $scope.isCityPassOrder = false;
      $scope.isCityPassRedemption = false;
      $scope.isCityPassC3Redemption = false;
      $scope.validCityPasses = [];
      $scope.resale = Configuration.Resale;
      setFormData();
      setOrderValues();
      $scope.cardErrors = {};
      $scope.receipt = $scope.search.receipt;
      $scope.email = $scope.search.email;
      $scope.contactEmail = Configuration.ContactEmail;
      $scope.supportPhone = Configuration.SupportPhone;
      $scope.spinnerClass = '';
      $scope.recaptchaResponse = null;
      $scope.enableSubmitButton = true;
      isZipRequired();

      // Freedom Initialize
      $scope.iframeHtml = null;
      $scope.sessionKey = null;
      fetchFreedompayIframe();

      console.log($scope)

      $window.addEventListener('message', handleIframeSubmit);

      /** Google Place auto-complete */
      $scope.gPlace;
    } 

    /** Freedompay */
    const fetchFreedompayIframe = () => {
      // Use Ruby ERB to generate the URL with the query parameter
      var url = '/api/v1/webstores/circle_line/freedompay/iframe?cart_id=' + $scope.cartToken;
    
      // Make the GET request with the generated URL
      $http.get(url).then((response) => {
        $scope.iframeHtml = $sce.trustAsHtml(response.data.data.attributes.html); 
        $scope.sessionKey = response.data.data.attributes.session_key;
      });
    };

    /** Freedompay iframe handler */
    const handleIframeSubmit = (e) => {
      if ($scope.sessionKey) {
        const message = e.data
        const data = message.data

        switch (message.type) {
          case 1:
            data.errors
            console.log('case1', data.errors)
            const hasSessionKeyError = data.errors.filter((err) =>
              err.message.toLowerCase().includes("'sessionkey' is invalid")
            )

            if (hasSessionKeyError) {
              // if there is a sessionKey invalid error, reset sessionkey
              // to force payment form to reload with new iframe and sessionKey
              // setSessionKey('')
            } else {
              // handleError('Payment Processing Error', data.errors[0].message)
            }
            break
          case 2:
            data.height
            console.log('case2')
            angular.element($document[0].querySelector('iframe')).css({
              'height': data.height + "px", 
            });
            break
          case 3:
            data.attributes
            console.log('case3', data.attributes)
            const paymentKey = data.paymentKeys[0]
            if ($scope.orderForm.$valid) {
              $scope.verifyTicketAvailability();
              processPaidOrder(paymentKey, data.attributes, $scope.sessionKey);
            } else {
               swal({
                "title": "Oops! Missing Customer Information",
                "text": "Please complete the customer information section, Agree to the Terms and Condition and recaptcha.",
                "type": "error"
               });
               $scope.resetCaptcha();
               $scope.touchErrorFields();
            }
            break
          case 4:
            data.message
            console.log('case4', data.message)
            if (
              data.isValid &&
              sessionStorage.getItem('checkoutDataSent') !== 'true'
            ) {
              // googleTagManager.sendCheckoutData(currentCart)
              sessionStorage.setItem('checkoutDataSent', true)
            }
            break
        }
      }
    }

    var setOrderValues = function() {
      if($scope.cartToken) {
        Cart.get($scope.cartToken)
          .then(function(response) {
            $scope.isCityPassOrder = response.is_city_pass_order;
            $scope.isCityPassRedemption = response.is_city_pass;
            $scope.isCityPassC3Redemption = response.is_city_pass_c3;
            $scope.validCityPasses = response.city_passes;
            setOrderInfo();
            getCheckout();
            setEventTime();
            setEventType();
          })
          .catch(function(error) {
            console.log('GetCart error', error);
          })
      } else {
        setOrderInfo();
        getCheckout();
        setEventTime();
        setEventType();
      }
    }

    var isZipRequired = function() {
      $scope.requireZip = $scope.customerDetails.countryCode == 'US';
    }

    $scope.$watch('customerDetails.countryCode', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        $scope.customerDetails.zip = null;
        isZipRequired();
      }
    }, true);

    var setOrderInfo = function() {
      $scope.orderInfo = {
        is_city_pass_order: $scope.isCityPassOrder,
        is_city_pass: $scope.isCityPassRedemption,
        is_city_pass_c3: $scope.isCityPassC3Redemption,
        city_passes: $scope.validCityPasses,
        event_type_id: $scope.eventTypeId,
        partner_id: $scope.partnerId,
        adult_quantity: setAdultQty(),
        child_quantity: setChildQty(),
        event_id: $scope.eventTimeId,
        section_id: $scope.sectionId,
        resource_id: $scope.resourceId,
        hold_capacity_id: $scope.holdCapacityId,
        hold_session_id: $scope.holdSessionId,
        start_date: $scope.startDate,
        end_date: $scope.startDate,
        coupon: $scope.coupon,
        cart_token: $scope.cartToken,
        extras: []
      }
      $scope.ticketTotal = $scope.orderInfo.adult_quantity + $scope.orderInfo.child_quantity;
      $scope.totalTicketsEligibleForExtras = $scope.orderInfo.adult_quantity;
      $scope.setCookies();
    }

    var setAdultQty = function() {
      if($scope.isCityPassOrder){
        var adultPasses = $scope.validCityPasses.filter(cp => cp.adult);
        return adultPasses.length;
      } else {
        return $scope.findOrSetQtyParam('adultQty');
      }
    }

    var setChildQty = function() {
      if($scope.isCityPassOrder){
        var childPasses = $scope.validCityPasses.filter(cp => cp.child);
        return childPasses.length;
      } else {
        return $scope.findOrSetQtyParam('childQty');
      }
    }

    var setEventType = function() {
      var eventTypeId = $scope.eventTypeId;
      var partnerId = $scope.partnerId;

      EventType.get({ id: eventTypeId, partner_id: partnerId }, function(response) {
        $scope.eventType = response.data.attributes;
        $scope.orderInfo.revenue_company = $scope.eventType.revenue_company;
        $scope.orderInfo.delivery_method_id = getDeliveryMethodId();
        $scope.orderInfo.event_type_mapping_id = $scope.eventType.id;
        $scope.orderInfo.combo = $scope.eventType.combo;
        $scope.environmentKey = setEnvironmentKey();
      });
    }

    $scope.findOrSetQtyParam = function(param) {
      var searchVal = parseInt($scope.search[param], 10);
      if (searchVal >= 0) {
        return searchVal;
      }
      var cookieVal = parseInt($cookies.get(param), 10)
      if (cookieVal >= 0) {
        return cookieVal;
      }
      return 0;
    }

    function getDeliveryMethodId() {
      if($scope.isCityPassOrder) {
        return $scope.eventType.city_pass_delivery_method_id || $scope.eventType.delivery_method_id;
      } 
    }

    var setFormData = function() {
      $scope.cardDetails = {
        kind: 'credit_card',
        first_name: null,
        last_name: null,
        number: null,
        verification_value: null,
        month: null,
        year: null,
        retained: true
      }

      $scope.customerDetails = {
        email: null,
        phone: null,
        address_line1: null,
        address_line2: null,
        city: null,
        state: null,
        country: gon.geoCountry || 'United States',
        countryCode: gon.geoCountryCode || 'US',
        zip: null
      }

      $scope.termsAgreed = null;
      $scope.emailOptIn = true;
    }

    $scope.addExtraToOrder = function(extra) {
      $scope.orderInfo.extras.push({
        quantity: extra.quantity,
        id: extra.id
      });
    }

    $scope.updateOrderWithSelectedExtras = function() {
      getCheckout();
    }

    $scope.supportEmail = function() {
      return 'message us at ' + $scope.contactEmail + ' or call ' + $scope.supportPhone + '.';
    }

    var getCheckout = function() {
      $scope.spinnerClass = 'spinner-overlay';
      $scope.checkout = null;
      var ticketTotalsValid = $scope.validateTicketLimit($scope.ticketTotal)
      if (ticketTotalsValid) {
        Checkout.save({ order: { order_info: $scope.orderInfo } }, function(response) {
          $scope.checkout = response;
          $scope.tickets = response.tickets.map((ticket) => {
            if($scope.isCityPassOrder) {
              var adultCityPass = $scope.validCityPasses.find(cp => cp.adult);
              var childCityPass = $scope.validCityPasses.find(cp => cp.child);
              var ticketPass = ticket.child ? childCityPass : adultCityPass;
              ticket.display_title = `${ticket.quantity} ${ticketPass.ticket_redemption_name}`;
            }
            return ticket
          });
          $scope.extras = response.extras;
          $scope.ticketTotal = $scope.ticketCount();
          $scope.orderTotal = response.totals.total;
          $scope.skipBillingDetails = $scope.orderTotal <= 0;
          $scope.totalCoupon = response.totals.total_coupon;
          $scope.totalTax = response.totals.total_taxes;
          $scope.couponDescription = response.totals.coupon_description;

          if (response.totals.invalid_for_groupon) {
            var link = '<a href="/results">here</a>';
            swal({
              title: "Invalid Groupon Order",
              text: "You must select exactly 2 adult tickets to apply the Groupon discount. You can do so " + link + ". If you continue to experience difficulties, " + $scope.supportEmail(),
              type: "error"
            });
            $scope.resetCoupon();
            getCheckout();
          }
          $scope.spinnerClass = '';
        });
      } else {
        $scope.spinnerClass = '';
        $scope.ticketLimitExceeded().then(function(){
          var backUrl = Configuration.BaseURL + '/' + Configuration.AppCompany + '?eventTypeId=' + $scope.eventTypeId;
          window.location = backUrl;
        })
      }
    }

    var setEventTime = function() {
      EventTime.get(
        {
          id: $scope.orderInfo.event_id,
          start_date: $scope.orderInfo.start_date,
          end_date: $scope.orderInfo.start_date,
          event_type_id: $scope.orderInfo.event_type_id,
          partner_id: $scope.orderInfo.partner_id
        }, function(response) {
          $scope.eventTime = response.data.data.attributes;
          $scope.ticketsAvailable = $scope.eventTime.vacancy;
          $scope.orderInfo.event_description = $scope.eventTime.description;
          $scope.orderInfo.start_date_time = $scope.eventTime.start_date_time;
          $scope.orderInfo.end_date_time = $scope.eventTime.end_date_time;
          $scope.orderInfo.event_start_date = $scope.eventTime.start_date_time;
          $scope.orderInfo.event_end_date = $scope.eventTime.end_date_time;
          $scope.orderInfo.event_display_start_time = $scope.eventTime.display_start_time;
          $scope.orderInfo.event_display_end_time = $scope.eventTime.display_end_time;
          $scope.orderInfo.event_show_time = $scope.eventTime.show_time;
          $scope.orderInfo.event_company = $scope.eventTime.company;

          submitCriteoEvents()
        }
      );
    }

    $scope.validateForm = function() {
      $scope.enableSubmitButton = false;
      if ($scope.orderForm.$valid) {
        $scope.verifyTicketAvailability();
      } else {
        $scope.enableSubmitButton = true;
        $scope.resetCaptcha();
        $scope.touchErrorFields();
      }
    }

    $scope.touchErrorFields = function() {
      angular.forEach($scope.orderForm.$error.required, function (field) {
        field.$setTouched();
      });
    }

    $scope.clearError = function(errorObject, errorKey) {
      if(errorObject) {
        errorObject[errorKey] = null
      }
    }

    $scope.validateInputError = function(attribute) {
      return $scope.orderForm[attribute].$touched && $scope.orderForm[attribute].$invalid;
    }

    $scope.validateCaptcha = function(captchaResponse) {
      var data = { captcha_response: captchaResponse }

      $http.post('/api/v1/verify', data).then(function(response) {
        if (!response.data.success) {
          swal("Invalid captcha response");
          $scope.resetCaptcha();
        }
      }, function(errorResponse) {
        swal("Unable to verify captcha. Try again.");
        $scope.resetCaptcha();
      });
    }

    $scope.resetCaptcha = function(response) {
      vcRecaptchaService.reload();
    }

    $scope.verifyTicketAvailability = function() {
      EventTime.get({id: $scope.orderInfo.event_id, start_date: $scope.orderInfo.start_date, end_date: $scope.orderInfo.start_date, event_type_id: $scope.orderInfo.event_type_id }, function(response) {
        $scope.ticketsAvailable = response.data.data.attributes.available;
        $scope.checkVacancy() ? $scope.submitPaymentInfo() : swal('Sorry, your tickets are no longer available.', 'Please edit your ticket selection and try again');
      });
    }

    $scope.ticketCount = function() {
      var totalTickets = _.reduce($scope.checkout.tickets, function(sum, ticket) {
        return sum + ticket.quantity;
      }, 0);
      return totalTickets;
    }

    $scope.checkVacancy = function() {
      return $scope.ticketsAvailable >= $scope.ticketTotal;
    }

    var setEnvironmentKey = function() {
      if (Configuration.Resale) {
        return setResaleEnvironmentKey();
      } else {
        return setStandardEnvironmentKey();
      }
    }

    var setResaleEnvironmentKey = function() {
      if ($scope.eventType.revenue_company == 'nywt') {
        return Configuration.ResaleNywtEnvironmentKey;
      } else {
        return Configuration.ResaleCircleLineEnvironmentKey;
      }
    }

    var setStandardEnvironmentKey = function() {
      if ($scope.eventType.revenue_company == 'nywt') {
        return Configuration.NywtEnvironmentKey;
      } else {
        return Configuration.CircleLineEnvironmentKey;
      }
    }

    $scope.countryCallback = function (selectedCountryObj) {
      if(selectedCountryObj) {
        $scope.customerDetails.country = selectedCountryObj.name;
        $scope.customerDetails.countryCode = selectedCountryObj.code;
      }
    };

    $scope.submitPaymentInfo = function() {
      $scope.spinnerClass = 'spinner-overlay';
      $scope.cardErrors = {};
      if($scope.skipBillingDetails) {
        submitFreeOrder() ;
      } 
    }

    

    var submitFreeOrder = function() {

      var customerInfo = {
        first_name: $scope.cardDetails.first_name,
        last_name: $scope.cardDetails.last_name,
        email: $scope.customerDetails.email,
        email_opt_out: !$scope.emailOptIn,
        address_line1: $scope.customerDetails.address_line1,
        address_line2: $scope.customerDetails.address_line2,
        city: $scope.customerDetails.city,
        state: $scope.customerDetails.state,
      }

      submitOrder(null, customerInfo, null)
    }

    var processPaidOrder = function(paymentKey, attributes, sessionKey) {
      var cardToken = paymentKey;
      //var month = response.data.transaction.payment_method.month;
      //var year = response.data.transaction.payment_method.year;
      var cardType = attributes.find(attr => attr.Key === 'CardIssuer');
      var expirationDate = attributes.find(attr => attr.Key === 'ExpirationDate');
      var maskedCardNumber = attributes.find(attr => attr.Key === 'MaskedCardNumber')
      var expirationString = expirationDate ? expirationDate.Value.replace("/","") : '';
      var expirationReference = expirationString.substring(0, 4);

      var customerInfo = {
        first_name: $scope.cardDetails.first_name,
        last_name: $scope.cardDetails.last_name,
        email: $scope.customerDetails.email,
        phone: $scope.customerDetails.phone,
        address_line1: $scope.customerDetails.address_line1,
        address_line2: $scope.customerDetails.address_line2,
        city: $scope.customerDetails.city,
        state: $scope.customerDetails.state,
        country: $scope.customerDetails.country,
        country_code: $scope.customerDetails.countryCode,
        zip: $scope.customerDetails.zip,
        card_type: cardType.Value,
        last_four_digits: maskedCardNumber.Value.substring(maskedCardNumber.Value.length-4, maskedCardNumber.Value.length),
        expiration_reference: expirationReference,
        email_opt_out: !$scope.emailOptIn,
      }

      submitOrder(cardToken, customerInfo, sessionKey)
    }

    var submitOrder = function(cardToken, customerInfo, sessionKey) {
      var data = {
        order: {
          card_token: cardToken,
          session_key: sessionKey,
          order_info: $scope.orderInfo,
          customer_info: customerInfo,
        },
      };

      $http.post(Configuration.API + Configuration.Routes.orders.authorize, data)
        .then(function(response) {
          // authorize payment on Spreedly
          var order = response.data.data.attributes;
          var url = Configuration.API + Configuration.Routes.orders.submit;
          data.token = order.token;
          return $http.post(url, data);
        })
        .then(function(response) {
          // order success, track on google and redirect to confirmation
          var order = response.data.data.attributes;
          $scope.enableSubmitButton = false;
          var transactionId = order.external_order_id;
          var internalOrderToken = data.token;
          var confirmationPath = order.confirmation_path;
          $scope.spinnerClass = '';
          $scope.enableSubmitButton = true;
          clearCityPassData()
          $scope.sendTransactionToGoogle(transactionId, internalOrderToken, confirmationPath)
        })
        .catch(function(error) {
          var errorData = error.data || {};
          var errors = errorData.errors || {};
          var errorMsg = errors.message || Configuration.DefaultErrorMessagge;
          $scope.orderError(errorMsg, error);
        })
        .finally(function() {
          $scope.spinnerClass = '';
          $scope.enableSubmitButton = true;
        })
    }

    $scope.orderError = function(errorMsg, options) {
      $scope.spinnerClass = '';
      $scope.enableSubmitButton = true;
      swal({ title: 'Error', text: errorMsg, type: 'error' });
    }

   

    $scope.sendTransactionToGoogle = function(transactionId, internalOrderToken, confirmationPath) {
      var companyName = $scope.eventType.company === 'nywt' ? 'NYWT' : 'Circle Line'
      if (window.dataLayer && $scope.checkout) {
        var gtmProductData = {
          'event': 'purchase',
          'transactionId': transactionId,
          'transactionAffiliation': companyName + ' Galaxy Checkout',
          'transactionTotal': $scope.checkout.totals.total,
          'transactionTax': $scope.checkout.totals.total_taxes,
          'transactionShipping': 0,
          'transactionProducts': setTransactionProducts(),
          'eventTimeout' : 2000, // force trigger eventCallback if GTM doesn't respond within 2 sec
          'eventCallback': function() {
            setOrderAsTracked(internalOrderToken);
            window.location = confirmationPath;
          }
        };
        dataLayer.push(gtmProductData);

        if('staging' !== 'production') {
          window.location = confirmationPath;
        }
      } else {
        window.location = confirmationPath;
      }
    }

    var clearCityPassData = function() {
      delete $scope.$storage.cityPasses;
      delete $scope.$storage.isCityPassOrder;
      delete $scope.$storage.isCityPassRedemption;
      delete $scope.$storage.isCityPassC3Redemption;
    }

    var setTransactionProducts = function() {
      return _.map($scope.checkout.tickets || [], function(ticket, index) {
        return {
          'name': ticket.description, // Product name. Required.
          'sku': ticket.plu, // SKU/code.
          'category': $scope.eventType.display_name, // Category or variation.
          'price': ticket.total_per, // Unit price.
          'quantity': ticket.quantity // Quantity.
        };
      });
    }

    var setOrderAsTracked = function(orderToken) {
      var data = { order: { tracked: true } };
      $http.post(Configuration.API + '/orders/' + orderToken + '/track', data).then(function(response) {
        console.log('order gtm tracked')
      });
    }

    var submitCriteoEvents = function() {
      if (window.criteo_q && $scope.checkout) {
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" :
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m"
        : "d";

        var criteoData = [
          { event: "setAccount", account: 60370 },
          { event: "setSiteType", type: deviceType },
          { event: "setEmail", email: "" },
          {
            event: "viewSearch",
            checkin_date: $scope.orderInfo.start_date,
            nbra: $scope.orderInfo.adult_quantity,
            nbrc: $scope.orderInfo.child_quantity,
          },
          {
            event: "viewBasket",
            item: [
              { id: $scope.eventTime.description, price: $scope.checkout.totals.total, quantity: 1 }
            ]
          }
        ];

        window.criteo_q.push(
          { event: "setAccount", account: 60370 },
          { event: "setSiteType", type: deviceType },
          { event: "setEmail", email: "" },
          {
            event: "viewSearch",
            checkin_date: $scope.orderInfo.start_date,
            nbra: $scope.orderInfo.adult_quantity,
            nbrc: $scope.orderInfo.child_quantity,
          },
          {
            event: "viewBasket",
            item: [
              { id: $scope.eventTime.description, price: $scope.checkout.totals.total, quantity: 1 }
            ]
          }
        );
      }
    }

    $scope.checkCoupon = function() {
      $scope.orderInfo.coupon = $scope.inputCoupon;
      if (!$scope.orderInfo.coupon || $scope.orderInfo.coupon.length === 0) {
        return $scope.invalidCoupon({
          title: 'Discount Code Required',
          message: 'Please make sure you enter a code and try again.'
        });
      }

      $scope.spinnerClass = 'spinner-overlay';

      var data = { order_info: $scope.orderInfo }

      $http.post(Configuration.API + '/coupons/validate_coupon', data).then(function(response) {
        $scope.spinnerClass = '';

        if (response.data.is_valid) {
          getCheckout();
        } else {
          $scope.invalidCoupon(response.data.error_details);
        }
      });
    }

    $scope.resetCoupon = function() {
      $scope.inputCoupon = null
      $scope.orderInfo.coupon = null;
    }

    $scope.invalidCoupon = function(errorDetails) {
      $scope.resetCoupon();
      getCheckout();
      swal({
        title: errorDetails.title,
        text: errorDetails.message + " If you continue to experience difficulties, " + $scope.supportEmail(),
        type: "error"
      });
    }

    $scope.startDateString = function() {
      return $scope.dateToString($scope.orderInfo.start_date);
    }

    $scope.setCookies = function() {
      $cookies.put('adultQty', $scope.orderInfo.adult_quantity);
      $cookies.put('childQty', $scope.orderInfo.child_quantity);
      $cookies.put('eventTypeId', $scope.orderInfo.event_type_id);
      if ($scope.orderInfo.event_id) {
        $cookies.put('eventTimeId', $scope.orderInfo.event_id);
        $cookies.put('sourceId', $scope.orderInfo.source_id);
      }
    }
}]);

