import { galaxyApp } from '../app'

galaxyApp.controller("BaseCtrl", [
  '$scope',
  '$filter',
  'Configuration',
  function($scope, $filter, Configuration) {
    $scope.init = function(){
      $scope.spinnerClass = '';
      $scope.maxTicketCount = Configuration.MaxTickets;
      $scope.groupPhone = Configuration.GroupPhone;
      sendGtmPageView();
    }

    $scope.goToPath = function(path){
      window.location = path;
    }

    $scope.dateToString = function(date){
      var result =  $filter('date')(date, "yyyy-MM-dd", "ET");
      return result
    }

    $scope.objectToStringQuery = function(obj) {
      var keyValuePairArrays = Object.entries(obj)
      return keyValuePairArrays
        .map(kvArray => `${kvArray[0]}=${kvArray[1]}`)
        .join('&')
    }

    $scope.numToArray = function(num) {
      var array = new Array();
      array.push(0);
      for(var i = 0; i < num; i++) {
        array.push(i+1);
      }
      return array ;
    }

    $scope.getApiErrorMessage = function(errorOject, errorTitle = 'Error') {
      var errorData = errorOject.data || {};
      var errors = errorData.errors || {};
      var errorMessage = errors.message || Configuration.DefaultErrorMessage;

      swal({
        title: errorTitle,
        text: errorMessage,
        type: 'error',
      });
    }

    $scope.validateTicketLimit = function(ticketTotal){
      return ticketTotal <= $scope.maxTicketCount;
    }

    $scope.ticketLimitExceeded = function(){
      var exceededCount = Configuration.Resale ? 51 : 15;

      return swal({
        title: 'Ticket Limit Exceeded',
        text: `There is a ${$scope.maxTicketCount} ticket maximum. To purchase ${exceededCount} or more tickets, please call our Groups line at ${$scope.groupPhone}.`,
      });
    }

    function sendGtmPageView() {
      var dataLayer = window.dataLayer || [];
      var fullPath = window.location.pathname + window.location.search;
      dataLayer.push({ event: "Pageview", path: fullPath });
    }
  }
]);

