// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()

require('jquery')
require('underscore/underscore')
require('sweetalert/dist/sweetalert.min')
require('angular')
require('lib/picker')
require('lib/picker.date')
require('ng-pickadate/ng-pickadate')
require('components/dropdown')
require('components/modal')
require('components/nav')
require('utils/date_utils')
require('angular-messages')
require('angular-recaptcha')
require('angular-resource')
require('ngstorage/ngStorage')
require('angular-cookies')
require('angular-sanitize')
require('angular-countries/dist/js/ngCountries.min')
require('angular-scroll/angular-scroll.min')
require('angular/app')
require('angular/services/configuration.js.erb')
require('angular/factories/capacity')
require('angular/factories/cart')
require('angular/factories/city_pass')
require('angular/factories/centaman_coupon')
require('angular/factories/checkout')
require('angular/factories/coupon_mapping_check')
require('angular/factories/event_time')
require('angular/factories/event_type')
require('angular/factories/extra')
require('angular/factories/order')
require('angular/factories/ticket')
require('angular/controllers/base')
require('angular/controllers/city_pass.js')
require('angular/controllers/confirmation.js')
require('angular/controllers/event_types.js.erb')
require('angular/controllers/order.js.erb')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
