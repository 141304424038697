import { galaxyApp } from '../app'

galaxyApp.factory('EventTime', ['$resource', '$filter', 'Configuration', function($resource, $filter, Configuration) {
  var parseResponse = function(response) {
    var updatedData = [];
    angular.forEach(response.data.data.attributes, function(rawEventTime, index) {
      var EventTime = buildEventTime(rawEventTime);
      updatedData.push(EventTime);
    });
    response.data.data.attributes = updatedData;
    return response;
  }

  var parseGetResponse = function(response) {
    var eventTime = buildEventTime(response.data.data.attributes);
    response.data.data.attributes = eventTime;
    return response;
  }

  var parseDate = function(date) {
    var array = date.split("-");
    var year = array[0];
    var month = array[1];
    var day = array[2].split('T')[0];
    var newDate = new Date(year, month - 1, day);
    return newDate;
  }

  var parseTime = function(time) {
    if (angular.isUndefined(time)) {
      return { hour: null, minute: null };
    } else {
      var timeElements = time.split(":");
      var hour = timeElements[0];
      var minute = timeElements[1];
      return { hour: parseInt(hour, 10), minute: parseInt(minute, 10) };
    }
  }

  var buildEventTime = function(rawEventTime) {
    var eventTime = {
      event_type_id: rawEventTime.event_type_id,
      id: rawEventTime.id,
      section_id: rawEventTime.section_id,
      resource_id: rawEventTime.resource_id,
      partner_id: rawEventTime.partner_id,
      event_name: rawEventTime.event_name,
      display_name: rawEventTime.display_name,
      event_type_description: rawEventTime.event_name,
      description: rawEventTime.event_name,
      pricing: rawEventTime.pricing,
      start_date_time: rawEventTime.start_date_time,
      start_date: parseDate(rawEventTime.start_date),
      end_date_time: rawEventTime.end_date_time,
      end_date: parseDate(rawEventTime.end_date),
      start_time: parseTime(rawEventTime.start_time),
      display_start_time: rawEventTime.display_start_time,
      end_time: parseTime(rawEventTime.end_time),
      display_end_time: rawEventTime.display_end_time,
      capacity: rawEventTime.total_capacity,
      remaining: rawEventTime.available,
      vacancy: rawEventTime.available,
      available: rawEventTime.available,
      sold_out: rawEventTime.sold_out,
      is_all_day: rawEventTime.is_all_day,
      show_time: rawEventTime.show_time,
      has_combos: rawEventTime.has_combos,
      combo: rawEventTime.combo,
      location: rawEventTime.location,
      event_description: rawEventTime.event_description,
      company: rawEventTime.company,
    }
    return eventTime;
  }

  return $resource(Configuration.BaseURL + Configuration.API + '/events/:id.json' + '?cached=false', { id: '@id', event_id: '@event_id' }, {
    'query': {
      method: 'get',
      isArray: false,
      interceptor: { response: parseResponse }
    },
    'get': {
      method: 'get',
      interceptor: { response: parseGetResponse }
    }
  });
}]);
